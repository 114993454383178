/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { IconButton, LinkButton, Button } from "./styles/LinkButton";

function SEO({ description, lang, meta, keywords, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const cookieAccepted = useMemo(() => {
    return (typeof window !== "undefined") && window.localStorage.getItem("cookieAccepted");
  }, []);

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title || site.siteMetadata.title}
        titleTemplate={title ? `%s | ${site.siteMetadata.title}` : undefined}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ]
          .concat(
            keywords.length > 0
              ? {
                  name: `keywords`,
                  content: keywords.join(`, `),
                }
              : []
          )
          .concat(meta)}
      >
{/* CSP, only allow local images */}
        {false && (
          <meta
            http-equiv="Content-Security-Policy"
            content="default-src 'self' data:; img-src 'self' data:; style-src 'self' 'unsafe-inline'; script-src 'self' 'unsafe-inline' 'unsafe-eval'; font-src 'self' data:; frame-src 'self';"
          />
        )}

        {/* {cookieAccepted && <script async src="https://www.googletagmanager.com/gtag/js?id=G-6R6DS71PXJ"></script>} */}
      </Helmet>

      <CookieAccpet />
    </>
  );
}

const CookieAccpetDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;

  .inner {
    background: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
  }
`;

const CookieAccpet = () => {
  const [isVisible, setIsVisible] = React.useState(false);
  useEffect(() => {
    const cookieAccepted = typeof window !== "undefined" && window.localStorage.getItem("cookieAccepted");
    setIsVisible(!cookieAccepted);
  }, []);

  if (!isVisible) {
    return null;
  }

  const handleAccept = () => {
    localStorage.setItem("cookieAccepted", true);
    window.location.reload();
  };

  return (
    <CookieAccpetDiv>
      <div className="inner">
        <h3>Wir bitten um Ihre Zustimmung</h3>
        <p>
          Wir verwenden Cookies und andere Technologien auf unserer Website. Manche sind essenziell, andere helfen uns
          diese Website zu verbessern und den Erfolg unserer Kampagnen zu messen. Weitere Informationen dazu finden Sie
          in unserer <a href="/datenschutz">Datenschutzerklärung</a>.
        </p>
        <Button onClick={handleAccept}>Alle akzeptieren</Button>
      </div>
    </CookieAccpetDiv>
  );
};

setTimeout(() => {
  const cookieAccepted = typeof window !== "undefined" && window.localStorage.getItem("cookieAccepted");

  if (!cookieAccepted) {
    return;
  }

  if (typeof window === "object") {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-6R6DS71PXJ");
  }
}, 100);

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
  title: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
};

export default SEO;
